<template>
    <v-btn
        dark
        rounded
        x-large
        :style="styles.button"
        color="black"
        class="font-weight-bold"
        href="/#contact"
    >お問い合わせ</v-btn>
</template>
<script>
export default {
    computed: {
        styles() {
            return {
                button: {
                    width: this.$vuetify.breakpoint.forPC ? 200 : '85%',
                    maxWidth: '400px'
                }
            }
        }
    }
}
</script>
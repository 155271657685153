<template>
    <v-navigation-drawer
        temporary
        right
        fixed
        height="auto"
        v-model="shown"
    >
        <div id="wrapper">
            <div class="d-flex">
                <img id="logo" src="@/assets/web-figures/logo.png" />
                <v-spacer></v-spacer>
                <v-btn icon x-large @click="shown = false;"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <v-list>
                <v-list-item
                    v-for="item, i in listItems"
                    :key="`drawer-list-item-${i}`"
                    :href="item.href"
                    @click="shown = false"
                >
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>
<script>
export default {
    data: () => ({
        listItems: [
            { title: 'トップ', href: '/' },
            { title: '機能', href: '/features' },
            { title: '料金・導入フロー', href: '/prices' },
            { title: '会社概要', href: 'https://www.iflab.co.jp/' },
            { title: 'お問い合わせ', href: '/#contact' },
        ]
    }),
    props: ['value'],
    computed: {
        shown: {
            get() { return this.value; },
            set(val) { this.$emit('input', val); }
        }
    }
}
</script>
<style scoped>
#wrapper {
    padding: 16px;
}
#logo {
    width: 150px;
    display: block;
}
</style>

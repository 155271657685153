<template>
    <v-app style="line-height:1.75!important;">
        <app-bar></app-bar>
        <!--<v-navigation-drawer v-model="drawer" fixed temporary style="opacity:1.0">
            <v-list nav>
                <v-list-item v-for="m in menuButtons" :key="m.name" link @click="goTo(m.goTo); drawer=false;">
                    <v-list-item-title>{{ m.label }}</v-list-item-title>
                </v-list-item>
                <v-list-item link href="/sign-up" @click="drawer=false;">
                    <v-list-item-title>サインアップ</v-list-item-title>
                </v-list-item>
                <v-list-item link href="/pamphlet" @click="drawer=false;">
                    <v-list-item-title>資料請求</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>-->

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import ducts from '@iflb/ducts-client'
import AppBar from '@/components/AppBar'

export default {
    name: 'App',
    components: { AppBar },

    data: () => ({
        duct: new ducts.Duct(),
        drawer: false,
        menuButtons: [
            {
                name: 'home',
                label: 'ホーム',
                goTo: '#home'
            },
            {
                name: 'features',
                label: '機能',
                goTo: '#features'
            },
            {
                name: 'scenarios',
                label: '利用例',
                goTo: '#scenarios',
            },
            //{
            //    name: 'contact',
            //    label: 'お問い合わせ',
            //    goTo: '#contact',
            //},
        ],
    }),
    methods: {
        goTo(to) {
            if(this.$route.path!=='/') {
                this.$router.push('/');
                setTimeout(() => { this.$vuetify.goTo(to); }, 200);
            } else {
                this.$vuetify.goTo(to);
            }
        }
    },
    mounted() {
        this.duct.open('/ducts/wsd');
    }
};
</script>
<style>
.v-application {
    font-family: 'Hiragino Sans', 'Noto Sans JP', 'Roboto', sans-serif;
}
.v-card__text {
    line-height: 1.75!important;
}
</style>
<template>
    <div>
        <v-app-bar app color="white" :height="barHeight">
            <img id="logo" src="@/assets/web-figures/logo.png" :height="logoHeight" @click="moveToTop" />
            <v-spacer></v-spacer>
            <template v-if="$vuetify.breakpoint.forPC">
                <v-btn text class="app-bar-button" href="/">
                    トップ
                </v-btn>
                <v-btn text class="app-bar-button" href="/features">
                    機能
                </v-btn>
                <v-btn text class="app-bar-button" href="/prices">
                    料金・導入フロー
                </v-btn>
                <v-btn text append-icon="mdi-open-in-new" class="app-bar-button" href="https://www.iflab.co.jp/" target="_blank">
                    会社概要<v-icon right>mdi-open-in-new</v-icon>
                </v-btn>
                <contact-button class="ml-12"></contact-button>
            </template>
            <template v-else>
                <v-app-bar-nav-icon @click="drawer = true;"></v-app-bar-nav-icon>
            </template>
        </v-app-bar>
        <navigation-drawer v-model="drawer"></navigation-drawer>
    </div>
</template>
<script>
import ContactButton from '@/components/ContactButton'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
export default {
    components: { ContactButton, NavigationDrawer },
    data: () => ({
        drawer: false,
    }),
    computed: {
        barHeight() {
            return this.$vuetify.breakpoint.lgAndUp ? 90 : 70;
        },
        logoHeight() {
            return this.$vuetify.breakpoint.lgAndUp ? 60 : 40;
        },
    },
    methods: {
        moveToTop() {
            window.location.href = '/';
        }
    },
    mounted() {
        console.log(this.$vuetify.breakpoint);
    }
}
</script>
<style scoped>
.app-bar-button {
    font-size: 16px!important;
}
#logo {
    cursor: pointer;
}
</style>

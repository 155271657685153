import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Object.defineProperty(vuetify.framework.breakpoint, 'forPC', {
  get: function() { return vuetify.framework.breakpoint.width > 1000 }
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

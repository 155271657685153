import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                tutti: '#e06b51',
                'tutti-lighten': '#ffe8e3'
            }
        }
    }
});
